import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { course as courseData, courseVariables } from "./__generated__/course";
import { allStudents as allStudentsData } from "./__generated__/allStudents";
import { Button, Select, Spin, Table } from "antd";
import { useAuth0 } from "../../lib/components/react-auth0-spa";
import { useState } from "react";
import {
  courseRegistrations as courseRegistrationsData,
  courseRegistrationsVariables,
} from "./__generated__/courseRegistrations";
import {
  session as sessionData,
  sessionVariables,
} from "./__generated__/session";
import CURRENT_SESSION from "../../lib/utils/current";
import { isTeacher, isAdmin } from "../../lib/utils/userTypes";
import sortByLastFirst from "../../lib/utils/sortByLastFirst";

export const GET_SESSION = gql`
  query session($session: String!) {
    session(session: $session) {
      transportOptions
    }
  }
`;

export const REMOVE_REGISTRATION = gql`
  mutation removeRegistration($id: String!) {
    removeRegistration(id: $id)
  }
`;

export const ADD_REGISTRATION = gql`
  mutation addRegistration(
    $student: String!
    $course: String!
    $session: String!
    $transport: String!
  ) {
    addRegistration(
      student: $student
      course: $course
      session: $session
      transport: $transport
    )
  }
`;

export const Course = () => {
  const GET_COURSE = gql`
    query course($id: String!) {
      course(id: $id) {
        day
        title
        instructor
        description
      }
    }
  `;

  const GET_STUDENT_LIST = gql`
    query allStudents {
      students {
        id
        first
        last
      }
    }
  `;

  const GET_COURSE_REGISTRATIONS = gql`
    query courseRegistrations($id: String!) {
      courseRegistrations(id: $id) {
        id
        student {
          id
          first
          last
          grade
          crew
        }
        transport
      }
    }
  `;

  const params = useParams();
  const id = params?.id ? params.id : "";
  const grade = params?.grade ? "/courses/" + params.grade : "/courses";
  const [addStudentId, setAddStudentId] = useState("<Select Student>");
  const [addTransport, setAddTransport] = useState("<Select Transportation>");
  const [removeRegistration] = useMutation(REMOVE_REGISTRATION, {
    refetchQueries: [GET_COURSE_REGISTRATIONS],
  });
  const [addRegistration] = useMutation(ADD_REGISTRATION, {
    refetchQueries: [GET_COURSE_REGISTRATIONS],
  });
  const courseQuery = useQuery<courseData, courseVariables>(GET_COURSE, {
    variables: { id },
  });

  const studentQuery = useQuery<allStudentsData>(GET_STUDENT_LIST);
  const sessionQuery = useQuery<sessionData, sessionVariables>(GET_SESSION, {
    variables: { session: CURRENT_SESSION },
  });
  const registrationsQuery = useQuery<
    courseRegistrationsData,
    courseRegistrationsVariables
  >(GET_COURSE_REGISTRATIONS, {
    variables: {
      id,
    },
  });

  let studentListData = studentQuery?.data?.students
    ? studentQuery.data.students
    : null;

  const { isLoading, user, isAuthenticated } = useAuth0();

  const title = courseQuery?.data?.course?.title
    ? courseQuery.data.course.title
    : "No Course Title Found";

  const day = courseQuery?.data?.course?.day
    ? courseQuery.data.course.day
    : "No Day Found";

  const instructor = courseQuery?.data?.course?.instructor
    ? courseQuery.data.course.instructor
    : "No instructor Found";

  const description = courseQuery?.data?.course?.description
    ? courseQuery.data.course.description
    : "No Description Found";

  let registrations = registrationsQuery?.data?.courseRegistrations
    ? registrationsQuery.data.courseRegistrations
    : [];

  const transportOptions = sessionQuery?.data?.session?.transportOptions
    ? sessionQuery.data.session.transportOptions
    : [];

  if (
    isLoading ||
    courseQuery.loading ||
    studentQuery.loading ||
    registrationsQuery.loading ||
    sessionQuery.loading
  )
    return <Spin />;

  if (courseQuery.error)
    return <h1>Course Error: {courseQuery.error.message}</h1>;
  if (studentQuery.error)
    return <h1>Student Error: {studentQuery.error.message}</h1>;
  if (registrationsQuery.error)
    return <h1>Registration Error: {registrationsQuery.error.message}</h1>;
  if (sessionQuery.error)
    return <h1>Session Error: {sessionQuery.error.message}</h1>;

  let children: JSX.Element[] = [];

  children.push(
    <h1 key="title" style={{ textAlign: "center" }}>
      {title} ({day})
    </h1>
  );
  children.push(
    <h2 key="instructor" style={{ textAlign: "center" }}>
      Instructor: {instructor}
    </h2>
  );
  children.push(<p key="description">{description}</p>);

  if (isAuthenticated && isTeacher(user)) {
    let columns = [
      {
        title: <p style={{ textAlign: "center" }}>First</p>,
        dataIndex: ["student", "first"],
        render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
      },
      {
        title: <p style={{ textAlign: "center" }}>Last</p>,
        dataIndex: ["student", "last"],
        render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
      },
      {
        title: <p style={{ textAlign: "center" }}>Grade</p>,
        dataIndex: ["student", "grade"],
        render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
      },
      {
        title: <p style={{ textAlign: "center" }}>Crew</p>,
        dataIndex: ["student", "crew"],
        render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
      },
      {
        title: <p style={{ textAlign: "center" }}>Transportation</p>,
        dataIndex: "transport",
        render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
      },
      {
        dataIndex: ["student", "id"],
        render: (text: string) => (
          <Button href={"/students/" + text}>Detail</Button>
        ),
      },
    ];

    if (isAdmin(user)) {
      columns.push({
        title: <></>,
        dataIndex: "id",
        render: (text: string) => (
          <Button
            onClick={async () => {
              await removeRegistration({
                variables: {
                  id: text,
                },
              });
            }}
          >
            Remove
          </Button>
        ),
      });
    }

    registrations = registrations.slice().sort((a, b) => {
      if (a.student.last === b.student.last) {
        return a.student.first < b.student.first ? -1 : 0;
      } else {
        return a.student.last < b.student.last ? -1 : 0;
      }
    });

    children.push(
      <Table
        key="courseStudentTable"
        size="middle"
        dataSource={registrations}
        columns={columns}
        rowKey="id"
      />
    );

    studentListData = studentListData
      ? studentListData.slice().sort((a: any, b: any) => {
          if (a.last === b.last) {
            return a.first < b.first ? -1 : 0;
          } else {
            return a.last < b.last ? -1 : 0;
          }
        })
      : [];

    const idsInCourse = registrations.map((data) => data.student.id);
    studentListData = studentListData
      .filter(({ id }) => !idsInCourse?.includes(id))
      .sort(sortByLastFirst);

    const { Option } = Select;

    children.push(
      <Select
        key="Select Student"
        onChange={(value: any) => {
          setAddStudentId(value);
        }}
        value={addStudentId}
        style={{ width: 200 }}
      >
        <Option key="empty" value="<Select Student>">
          {"<Select Student>"}
        </Option>
        {studentListData?.map((data) => {
          return (
            <Option key={data.id} value={data.id}>
              {data.last}, {data.first}
            </Option>
          );
        })}
      </Select>
    );

    children.push(
      <Select
        key="Select Transport"
        onChange={(value: any) => {
          setAddTransport(value);
        }}
        value={addTransport}
        style={{ width: 650 }}
      >
        <Option key="empty" value="<Select Transportation>">
          {"<Select Transportation>"}
        </Option>
        {transportOptions.map((data) => {
          return (
            <Option key={data} value={data}>
              {data}
            </Option>
          );
        })}
      </Select>
    );

    children.push(
      <Button
        key="add Button"
        onClick={async () => {
          await addRegistration({
            variables: {
              student: addStudentId,
              course: id,
              session: CURRENT_SESSION,
              transport: addTransport,
            },
          });
          setAddStudentId("<Select Student>");
          setAddTransport("<Select Transportation>");
        }}
        disabled={
          addStudentId === "<Select Student>" ||
          addTransport === "<Select Transportation>"
        }
      >
        Add Student to Course
      </Button>
    );
  }

  children.push(<br key="break" />);

  children.push(
    <Button key="backButton" href={grade}>
      Back
    </Button>
  );

  return <>{children}</>;
};
