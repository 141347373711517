import { useState, useEffect } from "react";
import { useAuth0 } from "../../lib/components/react-auth0-spa";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Spin, Table, Form, Button } from "antd";
import { students as studentsData } from "./__generated__/students";
import {
  addEditStudent as addEditStudentData,
  addEditStudentVariables,
} from "./__generated__/addEditStudent";
import { StudentEditForm } from "../../lib/components";
import { isAdmin, isTeacher } from "../../lib/utils/userTypes";

export const GET_STUDENTS = gql`
  query students {
    students {
      id
      first
      last
      grade
      crew
      email
    }
  }
`;

export const Students = () => {
  const ADD_EDIT_STUDENT = gql`
    mutation addEditStudent(
      $first: String!
      $last: String!
      $grade: String!
      $crew: String!
      $email: String!
    ) {
      addEditStudent(
        first: $first
        last: $last
        grade: $grade
        crew: $crew
        email: $email
      ) {
        id
        first
        last
        grade
        crew
        email
      }
    }
  `;

  const { loading, error, data } = useQuery<studentsData>(GET_STUDENTS);
  const [addEditStudent] = useMutation<
    addEditStudentData,
    addEditStudentVariables
  >(ADD_EDIT_STUDENT);

  const { isLoading, user, isAuthenticated } = useAuth0();
  const [form] = Form.useForm();

  const onFinish = async ({ first, last, grade, crew, email }: any) => {
    await addEditStudent({
      variables: {
        first,
        last,
        grade,
        crew,
        email,
      },
      refetchQueries: [GET_STUDENTS],
    });

    setLabel("Add Student");
    setInitialValues({
      first: "",
      last: "",
      grade: "",
      crew: "",
      email: "@bhrsdonline.org",
    });
    form.resetFields();
  };

  const onCancel = () => {
    setLabel("Add Student");
    setInitialValues({
      first: "",
      last: "",
      grade: "",
      crew: "",
      email: "@bhrsdonline.org",
    });
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  });

  let students = data?.students ? data.students : [];
  const [label, setLabel] = useState("Add Student");
  const [initialValues, setInitialValues] = useState<addEditStudentVariables>({
    first: "",
    last: "",
    grade: "",
    crew: "",
    email: "@bhrsdonline.org",
  });

  if (!isAuthenticated) {
    return <h1>Must be Logged In</h1>;
  }

  if (!user.email_verified) {
    return <h1>Verified Users Only - Log out and Check E-mail to Verify</h1>;
  }

  if (!isTeacher(user)) {
    return <h1>Access Limited to bhrsd.org Users</h1>;
  }

  if (loading || isLoading) return <Spin />;
  if (error) return <h1>Error: {error.message}</h1>;

  let columns = [
    {
      title: <p style={{ textAlign: "center" }}>First</p>,
      dataIndex: "first",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Last</p>,
      dataIndex: "last",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Grade</p>,
      dataIndex: "grade",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Crew</p>,
      dataIndex: "crew",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Student Email</p>,
      dataIndex: "email",
      key: "email",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      dataIndex: "id",
      key: "id",
      render: (text: string, _record: any) => (
        <Button href={"/students/" + text}>Detail</Button>
      ),
    },
  ];

  if (isAdmin(user)) {
    columns.push({
      title: <></>,
      dataIndex: "",
      key: "edit",
      render: (_text: string, record: any) => (
        <Button
          key={record.email}
          onClick={() => {
            setLabel("Save Edited Student");
            setInitialValues({
              first: record.first,
              last: record.last,
              grade: record.grade,
              crew: record.crew,
              email: record.email,
            });
          }}
        >
          Edit
        </Button>
      ),
    });
  }

  let children: JSX.Element[] = [];

  const sortFunction = (a: any, b: any) => {
    if (a.last === b.last) {
      return a.first < b.first ? -1 : 0;
    } else {
      return a.last < b.last ? -1 : 0;
    }
  };

  students = students.slice().sort(sortFunction);

  children.push(
    <Table
      key="studentsTable"
      size="middle"
      dataSource={students}
      columns={columns}
      rowKey="id"
    />
  );

  if (isAdmin(user)) {
    children.push(
      <StudentEditForm
        key="Student Edit Form"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        onCancel={onCancel}
        labelFinish={label}
        labelCancel={"Cancel"}
        disableEmail={user.email === initialValues.email}
      />
    );
  }

  return <>{children}</>;
};
