import React from "react";

export const Home = () => {
  return (
    <>
      <h2>Home Page</h2>
      <table>
        <thead>
          <tr>
            <th>Single Player</th>
            <th>Multi-Player</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="http://hochler.com/tictactoe">Tic-Tac-Toe</a>
            </td>
            <td>
              <a href="https://pc-tic-tac-toe.herokuapp.com">Tic-Tac-Toe</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="http://hochler.com/connectfour">Connect Four</a>
            </td>
            <td>
              <a href="https://pc-connect-four.herokuapp.com">Connect Four</a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
