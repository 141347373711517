import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useAuth0 } from "../../lib/components/react-auth0-spa";
import { Spin, Table, Button, Select } from "antd";
import {
  guardian as guardianData,
  guardianVariables,
} from "./__generated__/guardian";
import {
  sgByGuardian as sqByGuardianData,
  sgByGuardianVariables,
} from "./__generated__/sgByGuardian";
import {
  addEditStudentGuardian as addEditStudentGuardianData,
  addEditStudentGuardianVariables,
} from "./__generated__/addEditStudentGuardian";
import {
  removeSGStudent as removeSGStudentData,
  removeSGStudentVariables,
} from "./__generated__/removeSGStudent";
import { GET_STUDENTS } from "../Students";
import { students as studentsData } from "../Students/__generated__/students";
import { useState } from "react";
import { isAdmin } from "../../lib/utils/userTypes";

export const ADD_EDIT_STUDENT_GUARDIAN = gql`
  mutation addEditStudentGuardian($student: String!, $guardian: String!) {
    addEditStudentGuardian(student: $student, guardian: $guardian) {
      students {
        id
        first
        last
        grade
        crew
        email
      }
      guardians {
        id
        first
        last
        email
        phone
      }
    }
  }
`;

export const Guardian = () => {
  const params = useParams();
  const id = params?.id ? params.id : "";

  const GET_GUARDIAN = gql`
    query guardian($id: String!) {
      guardian(id: $id) {
        id
        last
        first
      }
    }
  `;

  const GET_GUARDIAN_STUDENTS = gql`
    query sgByGuardian($id: String!) {
      sgByGuardian(id: $id) {
        id
        students {
          id
          first
          last
          grade
          crew
        }
      }
    }
  `;

  const REMOVE_SG_STUDENT = gql`
    mutation removeSGStudent($id: String!) {
      removeSGStudent(id: $id) {
        id
        students {
          id
          first
          last
          grade
          crew
        }
      }
    }
  `;

  const guardianQuery = useQuery<guardianData, guardianVariables>(
    GET_GUARDIAN,
    {
      variables: { id },
    }
  );
  const guardianStudentQuery = useQuery<
    sqByGuardianData,
    sgByGuardianVariables
  >(GET_GUARDIAN_STUDENTS, {
    variables: { id },
  });
  const studentsQuery = useQuery<studentsData>(GET_STUDENTS);
  const [addStudentId, setAddStudentId] = useState("<Select Student>");
  const { isLoading, user, isAuthenticated } = useAuth0();
  const [addEditSG] = useMutation<
    addEditStudentGuardianData,
    addEditStudentGuardianVariables
  >(ADD_EDIT_STUDENT_GUARDIAN, { refetchQueries: [GET_GUARDIAN_STUDENTS] });
  const [removeSGStudent] = useMutation<
    removeSGStudentData,
    removeSGStudentVariables
  >(REMOVE_SG_STUDENT, { refetchQueries: [GET_GUARDIAN_STUDENTS] });

  if (!isAuthenticated) {
    return <h1>Must be Logged In</h1>;
  }

  if (!user.email_verified) {
    return <h1>Verified Users Only - Log out and Check E-mail to Verify</h1>;
  }

  if (guardianQuery.loading || guardianStudentQuery.loading || isLoading)
    return <Spin />;
  if (guardianQuery.error)
    return <h1>Guardian Data Error: {guardianQuery.error.message}</h1>;
  if (guardianStudentQuery.error)
    return (
      <h1>Guardian Student Data Error: {guardianStudentQuery.error.message}</h1>
    );

  let guardianStudents = guardianStudentQuery?.data?.sgByGuardian?.students
    ? guardianStudentQuery.data.sgByGuardian.students
    : [];

  let students = studentsQuery?.data?.students
    ? studentsQuery.data.students
    : [];

  let columns = [
    {
      title: <p style={{ textAlign: "center" }}>First</p>,
      dataIndex: "first",
      key: "first",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Last</p>,
      dataIndex: "last",
      key: "last",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Grade</p>,
      dataIndex: "grade",
      key: "grade",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Teacher / Crew</p>,
      dataIndex: "crew",
      key: "crew",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
  ];

  if (isAdmin(user)) {
    columns.push({
      title: <></>,
      dataIndex: "id",
      key: "id",
      render: (text: string) => (
        <Button onClick={() => removeSGStudent({ variables: { id: text } })}>
          Remove
        </Button>
      ),
    });
  }

  const sortFunction = (a: any, b: any) => {
    if (a.last === b.last) {
      return a.first < b.first ? -1 : 0;
    } else {
      return a.last < b.last ? -1 : 0;
    }
  };

  guardianStudents = guardianStudents.slice().sort(sortFunction);
  const ids = guardianStudents?.map(({ id }: { id: string }) => id);

  students = students
    .slice()
    .filter(({ id }) => !ids.includes(id))
    .sort(sortFunction);

  let children: JSX.Element[] = [];

  children.push(
    <h1 style={{ textAlign: "center" }} key="heading">
      {guardianQuery?.data?.guardian?.first}{" "}
      {guardianQuery?.data?.guardian?.last}
    </h1>
  );

  children.push(
    <Table
      key="Guardian Students"
      dataSource={guardianStudents}
      columns={columns}
      rowKey="id"
      pagination={false}
    />
  );

  if (isAdmin(user)) {
    const { Option } = Select;

    children.push(
      <Select
        key="Select"
        onChange={(value: any) => {
          setAddStudentId(value);
        }}
        value={addStudentId}
        style={{ width: 300 }}
      >
        <Option key="empty" value="<Select Student>">
          {"<Select Student>"}
        </Option>
        {students.map((data) => {
          return (
            <Option key={data.id} value={data.id}>
              {data.last}, {data.first}
            </Option>
          );
        })}
      </Select>
    );

    children.push(
      <Button
        key="Add"
        onClick={() => {
          addEditSG({ variables: { guardian: id, student: addStudentId } });
          setAddStudentId("<Select Student>");
        }}
        disabled={addStudentId === "<Select Student>"}
      >
        Add Student
      </Button>
    );
  }

  children.push(<br key="Break" />);

  children.push(
    <Button key="backButton" href="/guardians">
      Back
    </Button>
  );

  return <div>{children}</div>;
};
