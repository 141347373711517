import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { useAuth0 } from "../../lib/components/react-auth0-spa";
import CURRENT_SESSION from "../../lib/utils/current";
import { Spin, Table, Button, Select, Space } from "antd";
import {
  student as studentData,
  studentVariables,
} from "./__generated__/student";
import {
  studentRegistrations as studentRegistrationsData,
  studentRegistrationsVariables,
} from "./__generated__/studentRegistrations";
import {
  removeRegistration as removeRegistrationData,
  removeRegistrationVariables,
} from "../Course/__generated__/removeRegistration";
import {
  sgByStudent as sgByStudentData,
  sgByStudentVariables,
} from "./__generated__/sgByStudent";
import {
  removeSGGuardian as removeSGGuardianData,
  removeSGGuardianVariables,
} from "./__generated__/removeSGGuardian";
import { guardians as guardiansData } from "../Guardians/__generated__/guardians";
import { isAdmin } from "../../lib/utils/userTypes";
import { ADD_REGISTRATION, REMOVE_REGISTRATION } from "../Course";
import { GET_GUARDIANS } from "../Guardians";
import { ADD_EDIT_STUDENT_GUARDIAN } from "../Guardian";
import { GET_COURSES_BY_SESSION_GRADES } from "../Courses";
import { GET_SESSION } from "../Course";
import {
  addEditStudentGuardian as addEditStudentGuardianData,
  addEditStudentGuardianVariables,
} from "../Guardian/__generated__/addEditStudentGuardian";
import {
  coursesBySessionGrades as coursesBySessionGradesData,
  coursesBySessionGradesVariables,
} from "../Courses/__generated__/coursesBySessionGrades";
import {
  session as sessionData,
  sessionVariables,
} from "../Course/__generated__/session";
import {
  addRegistration as addRegistrationData,
  addRegistrationVariables,
} from "../Course/__generated__/addRegistration";
import sortByDayTitle from "../../lib/utils/sortByDayTitle";
import sortByLastFirst from "../../lib/utils/sortByLastFirst";

export const Student = () => {
  const params = useParams();
  const id = params?.id ? params.id : "";

  const GET_STUDENT = gql`
    query student($id: String!) {
      student(id: $id) {
        id
        last
        first
        grade
        crew
      }
    }
  `;

  const STUDENT_REGISTRATIONS = gql`
    query studentRegistrations($id: String!, $session: String!) {
      studentRegistrations(id: $id, session: $session) {
        id
        course {
          id
          day
          title
          instructor
        }
        transport
      }
    }
  `;

  const GET_STUDENT_GUARDIANS = gql`
    query sgByStudent($id: String!) {
      sgByStudent(id: $id) {
        id
        guardians {
          id
          first
          last
          email
          phone
        }
      }
    }
  `;

  const REMOVE_SG_GUARDIAN = gql`
    mutation removeSGGuardian($id: String!) {
      removeSGGuardian(id: $id) {
        id
        guardians {
          id
          first
          last
          email
          phone
        }
      }
    }
  `;

  const studentGuardianQuery = useQuery<sgByStudentData, sgByStudentVariables>(
    GET_STUDENT_GUARDIANS,
    { variables: { id } }
  );
  const studentQuery = useQuery<studentData, studentVariables>(GET_STUDENT, {
    variables: { id },
  });
  const guardiansQuery = useQuery<guardiansData>(GET_GUARDIANS);
  const [executeCourses, coursesQuery] = useLazyQuery<
    coursesBySessionGradesData,
    coursesBySessionGradesVariables
  >(GET_COURSES_BY_SESSION_GRADES);
  const [executeSession, sessionQuery] = useLazyQuery<
    sessionData,
    sessionVariables
  >(GET_SESSION);
  const { loading, error, data } = useQuery<
    studentRegistrationsData,
    studentRegistrationsVariables
  >(STUDENT_REGISTRATIONS, {
    variables: { id, session: CURRENT_SESSION },
  });
  const { isLoading, user, isAuthenticated } = useAuth0();
  const [removeRegistration] = useMutation<
    removeRegistrationData,
    removeRegistrationVariables
  >(REMOVE_REGISTRATION);
  const [removeGuardian] = useMutation<
    removeSGGuardianData,
    removeSGGuardianVariables
  >(REMOVE_SG_GUARDIAN);
  const [addEditSG] = useMutation<
    addEditStudentGuardianData,
    addEditStudentGuardianVariables
  >(ADD_EDIT_STUDENT_GUARDIAN, { refetchQueries: [GET_STUDENT_GUARDIANS] });
  const [addGuardianId, setAddGuardianId] = useState("<Select Guardian>");
  const [addCourseId, setAddCourseId] = useState("<Select Course>");
  const [addTransportation, setAddTransportation] = useState(
    "<Select Transportation>"
  );
  const [addRegistration] = useMutation<
    addRegistrationData,
    addRegistrationVariables
  >(ADD_REGISTRATION);

  if (!isAuthenticated) {
    return <h1>Must be Logged In</h1>;
  }

  if (!user.email_verified) {
    return <h1>Verified Users Only - Log out and Check E-mail to Verify</h1>;
  }

  if (
    loading ||
    studentQuery.loading ||
    studentGuardianQuery.loading ||
    guardiansQuery.loading ||
    isLoading
  )
    return <Spin />;
  if (error) return <h1>Registration Data Error: {error.message}</h1>;
  if (studentQuery.error)
    return <h1> Student Data Error: {studentQuery.error.message}</h1>;
  if (studentGuardianQuery.error)
    return (
      <h1>Student Guardian Data Error: {studentGuardianQuery.error.message}</h1>
    );
  if (guardiansQuery.error) {
    return <h1>Guardians Data Error: {guardiansQuery.error.message}</h1>;
  }

  if (isAdmin(user)) {
    const myGrade = studentQuery.data?.student?.grade
      ? studentQuery.data.student.grade
      : "";
    if (!coursesQuery.called) {
      executeCourses({
        variables: { session: CURRENT_SESSION, grades: [myGrade] },
      });
    }
    if (coursesQuery.called && coursesQuery.loading) {
      return <Spin />;
    }
    if (!sessionQuery.called) {
      executeSession({ variables: { session: CURRENT_SESSION } });
    }
    if (sessionQuery.called && coursesQuery.loading) {
      return <Spin />;
    }
  }

  let columns = [
    {
      title: <p style={{ textAlign: "center" }}>Day</p>,
      dataIndex: ["course", "day"],
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Title</p>,
      dataIndex: ["course", "title"],
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Instructor</p>,
      dataIndex: ["course", "instructor"],
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Transportation</p>,
      dataIndex: "transport",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      dataIndex: ["course", "id"],
      render: (text: string) => (
        <Button href={"/courses/" + text}>Detail</Button>
      ),
    },
  ];

  if (isAdmin(user)) {
    columns.push({
      title: <></>,
      dataIndex: "id",
      render: (text: string) => (
        <Button
          onClick={() =>
            removeRegistration({
              variables: { id: text },
              refetchQueries: [STUDENT_REGISTRATIONS],
            })
          }
        >
          Remove
        </Button>
      ),
    });
  }

  let guardianColumns = [
    {
      title: <p style={{ textAlign: "center" }}>First</p>,
      dataIndex: "first",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Last</p>,
      dataIndex: "last",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Phone</p>,
      dataIndex: "phone",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Email</p>,
      dataIndex: "email",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
  ];

  if (isAdmin(user)) {
    guardianColumns.push({
      title: <></>,
      dataIndex: "id",
      render: (text: string) => (
        <Button
          onClick={() =>
            removeGuardian({
              variables: { id: text },
              refetchQueries: [GET_STUDENT_GUARDIANS],
            })
          }
        >
          Remove
        </Button>
      ),
    });
  }

  let children: JSX.Element[] = [];
  const { Option } = Select;

  let courses = data?.studentRegistrations ? data.studentRegistrations : [];
  courses = courses.slice().sort(sortByDayTitle);

  let studentGuardians = studentGuardianQuery?.data?.sgByStudent?.guardians
    ? studentGuardianQuery.data.sgByStudent.guardians
    : [];
  const ids = studentGuardians?.map(({ id }: { id: string }) => id);

  studentGuardians = studentGuardians.slice().sort(sortByLastFirst);

  let guardians = guardiansQuery?.data?.guardians
    ? guardiansQuery.data.guardians
    : [];
  guardians = guardians
    .slice()
    .filter(({ id }) => !ids.includes(id))
    .sort(sortByLastFirst);

  children.push(
    <h1 style={{ textAlign: "center" }} key="heading">
      {studentQuery?.data?.student?.first} {studentQuery?.data?.student?.last}{" "}
      (Grade: {studentQuery.data?.student?.grade} -{" "}
      {studentQuery?.data?.student?.crew})
    </h1>
  );

  children.push(
    <Table
      key="coursesTable"
      size="middle"
      dataSource={courses}
      columns={columns}
      pagination={false}
      rowKey="id"
    />
  );

  if (isAdmin(user)) {
    const courseChoices = coursesQuery?.data?.coursesBySessionGrades
      ? coursesQuery.data.coursesBySessionGrades.slice().sort(sortByDayTitle)
      : [];
    const transportChoices = sessionQuery.data?.session?.transportOptions
      ? sessionQuery.data.session.transportOptions
      : [];

    children.push(
      <Space key="Course Space">
        <Select
          key="Select Course"
          value={addCourseId}
          onChange={(value) => setAddCourseId(value)}
          style={{ width: 350 }}
        >
          <Option value="<Select Course>" key="empty">
            {"<Select Course>"}
          </Option>
          {courseChoices.map((data) => {
            return (
              <Option key={data.id} value={data.id}>
                {data.title} ({data.day})
              </Option>
            );
          })}
        </Select>
        <Select
          key="Select Transportation"
          onChange={(value) => setAddTransportation(value)}
          value={addTransportation}
          style={{ width: 500 }}
        >
          <Option value="<Select Transportation>" key="empty">
            {"<Select Transportation>"}
          </Option>
          {transportChoices.map((data) => {
            return (
              <Option key={data} value={data}>
                {data}
              </Option>
            );
          })}
        </Select>
        <Button
          key="add Course"
          onClick={async () => {
            await addRegistration({
              variables: {
                student: id,
                course: addCourseId,
                session: CURRENT_SESSION,
                transport: addTransportation,
              },
              refetchQueries: [STUDENT_REGISTRATIONS],
            });
            setAddCourseId("<Select Course>");
            setAddTransportation("<Select Transportation>");
          }}
          disabled={
            addCourseId === "<Select Course>" ||
            addTransportation === "<Select Transportation>"
          }
        >
          Add Course
        </Button>
      </Space>
    );
  }

  children.push(
    <Table
      key="Guardian Table"
      size="middle"
      dataSource={studentGuardians}
      columns={guardianColumns}
      rowKey="id"
      pagination={false}
    />
  );

  if (isAdmin(user)) {
    children.push(
      <Space key="Guardian Space">
        <Select
          key="Select"
          onChange={(value: any) => {
            setAddGuardianId(value);
          }}
          value={addGuardianId}
          style={{ width: 300 }}
        >
          <Option key="empty" value="<Select Guardian>">
            {"<Select Guardian>"}
          </Option>
          {guardians.map((data) => {
            return (
              <Option key={data.id} value={data.id}>
                {data.last}, {data.first}
              </Option>
            );
          })}
        </Select>
        <Button
          key="Add"
          onClick={() => {
            addEditSG({ variables: { student: id, guardian: addGuardianId } });
            setAddGuardianId("<Select Guardian>");
          }}
          disabled={addGuardianId === "<Select Guardian>"}
        >
          Add Guardian
        </Button>
      </Space>
    );
  }

  children.push(<br key="Break" />);

  children.push(
    <Button key="backButton" href="/students">
      Back
    </Button>
  );

  return <div>{children}</div>;
};
