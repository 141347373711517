import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { useAuth0 } from "../react-auth0-spa";
import LogInButton from "../LogInButton";
import LogOutButton from "../LogOutButton";
import {
  HomeOutlined,
  QuestionCircleOutlined,
  BookOutlined,
  TeamOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { isTeacher } from "../../utils/userTypes";

export const AppHeader = () => {
  const [current, setCurrent] = useState("");
  const { isLoading, user } = useAuth0();

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  if (isLoading) return <Spin />;

  const logInOutButton = user ? <LogOutButton /> : <LogInButton />;

  const studentsLink =
    user?.email && isTeacher(user) ? (
      <>
        <Menu.Item key="students" icon={<TeamOutlined />}>
          <NavLink to="/students">Students</NavLink>
        </Menu.Item>
        <Menu.Item key="guardians" icon={<TeamOutlined />}>
          <NavLink to="/guardians">Guardians</NavLink>
        </Menu.Item>
      </>
    ) : undefined;

  return (
    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <NavLink to="/">Home</NavLink>
      </Menu.Item>
      <Menu.Item key="about" icon={<QuestionCircleOutlined />}>
        <NavLink to="/about">About</NavLink>
      </Menu.Item>
      <Menu.Item key="courses" icon={<BookOutlined />}>
        <NavLink to="/courses">Courses</NavLink>
      </Menu.Item>
      {studentsLink}
      <Menu.Item key="loginout">{logInOutButton}</Menu.Item>
    </Menu>
  );
};
