import React from "react";
import { Button } from "antd";
import { useAuth0 } from "../react-auth0-spa";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button key="login" onClick={loginWithRedirect}>
      Log In
    </Button>
  );
};

export default LoginButton;
