import { User } from "@auth0/auth0-spa-js";

export const isAdmin = (user: User) => {
  if (
    user.email_verified &&
    (user.email === "mhochler@mac.com" ||
      user.email === "thomas.kelly@bhrsd.org")
  )
    return true;
  else return false;
};

export const isTeacher = (user: User) => {
  const email = user?.email ? user.email : "@";
  const emailSplit = email.split("@");
  const userEmailDomain = emailSplit[emailSplit.length - 1].toLowerCase();

  if (
    user.email_verified &&
    (user.email === "mhochler@mac.com" ||
      user.email === "mhochler@icloud.com" ||
      userEmailDomain === "bhrsd.org")
  )
    return true;
  else return false;
};
