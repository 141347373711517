import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  coursesBySessionGrades as coursesBySessionGradesData,
  coursesBySessionGradesVariables,
} from "./__generated__/coursesBySessionGrades";
import { Table, Radio, Spin, Button, Space } from "antd";
import { useParams } from "react-router";
import CURRENT_SESSION from "../../lib/utils/current";
import sortByDayTitle from "../../lib/utils/sortByDayTitle";

export const GET_COURSES_BY_SESSION_GRADES = gql`
  query coursesBySessionGrades($session: String!, $grades: [String!]!) {
    coursesBySessionGrades(session: $session, grades: $grades) {
      id
      day
      grades
      title
      instructor
      description
    }
  }
`;

export const Courses = () => {
  const params = useParams();
  const initialGrade = params?.id ? params.id : "1";
  const [grade, setGrade] = useState(initialGrade);

  const { loading, error, data, refetch } = useQuery<
    coursesBySessionGradesData,
    coursesBySessionGradesVariables
  >(GET_COURSES_BY_SESSION_GRADES, {
    variables: { session: CURRENT_SESSION, grades: Array(grade) },
  });

  if (loading) return <Spin />;
  if (error) return <h1>Error: {error.message}</h1>;

  const onChangeGrade = (e: any) => {
    setGrade(e.target.value);
    refetch({ grades: Array(e.target.value) });
  };

  let courses =
    data && data.coursesBySessionGrades ? data.coursesBySessionGrades : [];

  const columns = [
    {
      title: <p style={{ textAlign: "center" }}>Day</p>,
      dataIndex: "day",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Title</p>,
      dataIndex: "title",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      title: <p style={{ textAlign: "center" }}>Instructor</p>,
      dataIndex: "instructor",
      render: (text: string) => <p style={{ textAlign: "center" }}>{text}</p>,
    },
    {
      dataIndex: "id",
      render: (text: string) => (
        <Button href={"/courses/" + text + "/" + grade}>Detail</Button>
      ),
    },
  ];

  let children: JSX.Element[] = [];
  children.push(
    <Radio.Group key="grade" onChange={onChangeGrade} value={grade}>
      <Space>
        <Radio key="g1" value={"1"}>
          1st Grade
        </Radio>
        <Radio key="g2" value={"2"}>
          2nd Grade
        </Radio>
        <Radio key="g3" value={"3"}>
          3rd Grade
        </Radio>
        <Radio key="g4" value={"4"}>
          4th Grade
        </Radio>
        <Radio key="g5" value={"5"}>
          5th Grade
        </Radio>
        <Radio key="g6" value={"6"}>
          6th Grade
        </Radio>
        <Radio key="g7" value={"7"}>
          7th Grade
        </Radio>
        <Radio key="g8" value={"8"}>
          8th Grade
        </Radio>
      </Space>
    </Radio.Group>
  );

  courses = courses.slice().sort(sortByDayTitle);

  children.push(
    <Table
      key="coursesTable"
      size="middle"
      dataSource={courses}
      columns={columns}
      pagination={false}
      rowKey="id"
    />
  );

  return <div>{children}</div>;
};
