import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout, Spin } from "antd";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { Auth0Provider, useAuth0 } from "./lib/components/react-auth0-spa";
import "./App.css";
import {
  Home,
  About,
  Courses,
  Course,
  Students,
  Student,
  NotFound,
  Guardian,
  Guardians,
} from "./sections";
import { AppHeader } from "./lib/components/AppHeader";
import history from "./lib/utils/history";

interface AppState {
  targetUrl: string;
}

const onRedirectCallback = (appState: AppState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
  console.log(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (
    window.location.search.includes("code=") &&
    window.location.search.includes("state=")
  ) {
    return <Spin size="large" />;
  }

  const { Header, Content } = Layout;

  return (
    <BrowserRouter>
      <Layout id="app">
        <Header>
          <AppHeader />
        </Header>
        <Content>
          <Routes>
            <Route index element={<Home />} />
            <Route path="about/*" element={<About />} />
            <Route path="courses" element={<Courses />} />
            <Route path="courses/:id" element={<Course />} />
            <Route path="courses/:id/:grade" element={<Course />} />
            <Route path="guardians" element={<Guardians />} />
            <Route path="guardians/:id" element={<Guardian />} />
            <Route path="students" element={<Students />} />
            <Route path="students/:id" element={<Student />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Content>
      </Layout>
    </BrowserRouter>
  );
};

const client = new ApolloClient({
  uri: "/api",
  cache: new InMemoryCache({
    typePolicies: {
      StudentGuardian: {
        fields: {
          students: { merge: false },
          guardians: { merge: false },
        },
      },
    },
  }),
});

ReactDOM.render(
  <Auth0Provider
    domain="project-connection.us.auth0.com"
    client_id="7uUhhNWAH6mB9art5blYYMDUFKgJJbvR"
    redirect_uri={window.location.origin}
    // @ts-ignore
    onRedirectCallback={onRedirectCallback}
  >
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

export default App;
