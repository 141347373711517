const sortByDayTitle = (a, b) => {
  if (a.day === b.day) {
    return a.title < b.title ? -1 : 0;
  } else {
    switch (a.day) {
      case "Monday":
        return -1;
      case "Tuesday":
        return b.day === "Monday" ? 1 : -1;
      case "Wednesday":
        return b.day === "Monday" || b.day === "Tuesday" ? 1 : -1;
      case "Thursday":
        return b.day === "Monday" ||
          b.day === "Tuesday" ||
          b.day === "Wednesday"
          ? 1
          : -1;
      default:
        return 1;
    }
  }
};

export default sortByDayTitle;
