import { Button } from "antd";
import { useAuth0 } from "../react-auth0-spa";

const LogoutButton = () => {
  const { logout, user } = useAuth0();

  return (
    <Button onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out ({user.name})
    </Button>
  );
};

export default LogoutButton;
