import { Table } from "antd";
import _ from "lodash";

export const GuardianListTable = ({
  data,
  columns,
}: {
  data: any;
  columns: any;
}) => {
  let guardiansData: any = [];

  data.forEach((q: any) => {
    guardiansData.push(
      _.assign({
        key: q.id,
        first: q.first,
        last: q.last,
        email: q.email,
        phone: q.phone,
        edit: {
          id: q.id,
          first: q.first,
          last: q.last,
          email: q.email,
          phone: q.phone,
        },
      })
    );
  });

  return (
    <Table
      key="guardiansTable"
      size="middle"
      dataSource={guardiansData}
      columns={columns}
    />
  );
};
