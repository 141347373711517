import { Form, Input, Button, Space } from "antd";
import { MaskedInput } from "antd-mask-input";
import { addEditGuardianVariables } from "../../../sections/Guardians/__generated__/addEditGuardian";

export const GuardianEditForm = ({
  initialValues,
  form,
  onFinish,
  onCancel,
  disableEmail,
  labelFinish,
  labelCancel,
}: {
  initialValues: addEditGuardianVariables;
  form: any;
  onFinish: any;
  onCancel: any;
  disableEmail: boolean;
  labelFinish: string;
  labelCancel: string;
}) => {
  const cancelButton = (
    <Button
      key="Cancel Button"
      type="default"
      htmlType="reset"
      onClick={onCancel}
    >
      {labelCancel}
    </Button>
  );

  return (
    <Form
      key="Add/Edit Guardian Form"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="first"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input guardian first name!",
          },
        ]}
      >
        <Input placeholder="Enter Guardian First Name" />
      </Form.Item>
      <Form.Item
        name="last"
        label="Last Name"
        rules={[
          {
            required: true,
            message: "Please input guardian last name!",
          },
        ]}
      >
        <Input placeholder="Enter Guardian Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Guardian E-mail Address"
        rules={[
          {
            required: true,
            type: "email",
            message: "The input is not valid E-mail Address!",
          },
        ]}
      >
        <Input
          placeholder="Enter Guardian E-mail Address"
          disabled={disableEmail}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Guardian Phone Number"
        rules={[
          {
            required: true,
            message: "Please input guardian phone number!",
          },
        ]}
      >
        <MaskedInput
          mask="(111) 111-1111"
          placeholder="Enter Guardian Telephone Number"
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            {labelFinish}
          </Button>
          {cancelButton}
        </Space>
      </Form.Item>
    </Form>
  );
};
