import { useState, useEffect } from "react";
import { useAuth0 } from "../../lib/components/react-auth0-spa";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Spin, Form, Button } from "antd";
import { GuardianEditForm } from "../../lib/components";
import { guardians as guardiansData } from "./__generated__/guardians";
import { GuardianListTable } from "../../lib/components/GuardianListTable";
import {
  addEditGuardian as addEditGuardianData,
  addEditGuardianVariables,
} from "./__generated__/addEditGuardian";
import _ from "lodash";
import { isAdmin, isTeacher } from "../../lib/utils/userTypes";

export const GET_GUARDIANS = gql`
  query guardians {
    guardians {
      id
      first
      last
      email
      phone
    }
  }
`;

export const Guardians = () => {
  const ADD_EDIT_GUARDIAN = gql`
    mutation addEditGuardian(
      $first: String!
      $last: String!
      $email: String!
      $phone: String!
    ) {
      addEditGuardian(
        first: $first
        last: $last
        email: $email
        phone: $phone
      ) {
        id
        first
        last
        email
        phone
      }
    }
  `;

  const { loading, error, data } = useQuery<guardiansData>(GET_GUARDIANS);
  const [addEditGuardian] = useMutation<
    addEditGuardianData,
    addEditGuardianVariables
  >(ADD_EDIT_GUARDIAN);

  const { isLoading, user, isAuthenticated } = useAuth0();
  const [form] = Form.useForm();

  const onFinish = async ({ first, last, email, phone }: any) => {
    await addEditGuardian({
      variables: {
        first,
        last,
        email,
        phone,
      },
      refetchQueries: [GET_GUARDIANS],
    });

    setLabel("Add Guardian");
    setInitialValues({ first: "", last: "", email: "", phone: "" });
    form.resetFields();
  };

  const onCancel = () => {
    setLabel("Add Guardian");
    setInitialValues({ first: "", last: "", email: "", phone: "" });
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  });

  const guardians = data?.guardians ? data.guardians : [];
  const [label, setLabel] = useState("Add Guardian");
  const [initialValues, setInitialValues] = useState<addEditGuardianVariables>({
    first: "",
    last: "",
    email: "",
    phone: "",
  });

  if (!isAuthenticated) {
    return <h1>Must be Logged In</h1>;
  }

  if (!user.email_verified) {
    return <h1>Verified Users Only - Log out and Check E-mail to Verify</h1>;
  }

  if (!isTeacher(user)) {
    return <h1>Access Limited to bhrsd.org Users</h1>;
  }

  if (loading || isLoading) return <Spin />;
  if (error) return <h1>Error: {error.message}</h1>;

  let columns = [
    {
      title: <p style={{ textAlign: "center" }}>First</p>,
      dataIndex: "first",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Last</p>,
      dataIndex: "last",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Guardian Email</p>,
      dataIndex: "email",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Guardian Phone</p>,
      dataIndex: "phone",
      render: (text: string, _record: any) => (
        <p style={{ textAlign: "center" }}>{text}</p>
      ),
    },
    {
      dataIndex: "key",
      render: (text: string, _record: any) => (
        <Button href={"/guardians/" + text}>Detail</Button>
      ),
    },
  ];

  if (isAdmin(user)) {
    columns.push({
      dataIndex: "edit",
      render: (_text: string, record: any) => (
        <Button
          onClick={() => {
            setLabel("Save Edited Guardian");
            setInitialValues({
              first: record.first,
              last: record.last,
              email: record.email,
              phone: record.phone,
            });
          }}
        >
          Edit
        </Button>
      ),
    });
  }

  let sorted = _.clone(guardians).sort((a: any, b: any) => {
    if (a.last === b.last) {
      return a.first < b.first ? -1 : 0;
    } else {
      return a.last < b.last ? -1 : 0;
    }
  });

  let guardiansData: any = [];

  sorted.forEach((q: any) => {
    guardiansData.push(
      _.assign({
        id: q.id,
        key: q.id,
        first: q.first,
        last: q.last,
        email: q.email,
        phone: q.phone,
        edit: {
          id: q.id,
          first: q.first,
          last: q.last,
          email: q.email,
          phone: q.phone,
        },
      })
    );
  });

  let children: JSX.Element[] = [];

  children.push(
    <GuardianListTable
      key="Guardian List table"
      data={guardiansData}
      columns={columns}
    />
  );

  if (isAdmin(user)) {
    children.push(
      <GuardianEditForm
        key="Guardian Edit Form"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        onCancel={onCancel}
        labelFinish={label}
        labelCancel={"Cancel"}
        disableEmail={user.email === initialValues.email}
      />
    );
  }

  return <>{children}</>;
};
