import { Form, Input, Button, Space } from "antd";
import { addEditStudentVariables } from "../../../sections/Students/__generated__/addEditStudent";

export const StudentEditForm = ({
  initialValues,
  form,
  disableEmail,
  labelFinish,
  labelCancel,
  onFinish,
  onCancel,
}: {
  initialValues: addEditStudentVariables;
  form: any;
  disableEmail: boolean;
  labelFinish: string;
  labelCancel: string | null;
  onFinish: any;
  onCancel: any | null;
}) => {
  const cancelButton = (
    <Button
      key="Cancel Button"
      type="default"
      htmlType="reset"
      onClick={onCancel}
    >
      {labelCancel}
    </Button>
  );

  return (
    <Form
      key="Add/Edit Student Form"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="first"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input student first name!",
          },
        ]}
      >
        <Input placeholder="Enter Student First Name" />
      </Form.Item>
      <Form.Item
        name="last"
        label="Last Name"
        rules={[
          {
            required: true,
            message: "Please input student last name!",
          },
        ]}
      >
        <Input placeholder="Enter Student Last Name" />
      </Form.Item>
      <Form.Item
        name="grade"
        label="Grade"
        rules={[
          {
            required: true,
            message: "Please input student grade!",
          },
        ]}
      >
        <Input placeholder="Enter Student Grade" />
      </Form.Item>
      <Form.Item
        name="crew"
        label="Crew"
        rules={[
          {
            required: true,
            message: "Please input student teacher / crew leader!",
          },
        ]}
      >
        <Input placeholder="Enter Student Teacher / Crew Leader" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Student E-mail Address"
        rules={[
          {
            required: true,
            type: "email",
            message: "The input is not valid E-mail Address!",
          },
        ]}
      >
        <Input
          placeholder="Enter Student E-mail Address"
          disabled={disableEmail}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8, offset: 10 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            {labelFinish}
          </Button>
          {cancelButton}
        </Space>
      </Form.Item>
    </Form>
  );
};
